import * as React from "react";
import { Link } from "gatsby";
// import Header from "../components/sections/Header";
// import ServicesPageSection from "../components/sections/ServicesPageSection";
// import Footer from "../components/sections/Footer";
// import ServicePageItem from "../components/elements/ServicePageItem";
import PageTitle from "../components/elements/PageTitle";

const ServicesPage = () => (
  <>
    <PageTitle pageTitle={"404"} />
    <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-20 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto">
        <main className="sm:flex">
          <p className="text-4xl font-extrabold text-sky-900 sm:text-5xl">
            404
          </p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-extrabold text-blue-gray-900 tracking-tight sm:text-5xl">
                Page not found
              </h1>
              <p className="mt-1 text-base text-blue-gray-900">
                Please check the URL in the address bar and try again.
              </p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <Link
                to="/en/"
                className="inline-flex inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-600"
              >
                Go back home
              </Link>
            </div>
          </div>
        </main>
      </div>
    </div>
  </>
  // <div className="bg-white">
  //   <PageTitle
  //     pageTitle={"Our services"}
  //     description={`Observe services IT grows provide. IT consulting. Software development. Product Development. QA and testing. Devops. Smart Teams`}
  //   />
  //   <Header />
  //   <ServicesPageSection
  //     title={"Our services"}
  //     // description={"which could move your business to the next level"}
  //   >
  //     <ServicePageItem
  //       serviceTitle={"CONCEPTS INTO SOLUTION"}
  //       title={"IT consulting"}
  //       description={`IT grows helps companies in different industries create optimal digital environments corresponding to their technical capabilities, business requirements, needs of employees and clients expectations.`}
  //       features={[
  //         "Scalable solutions",
  //         "Intelligent software for businesses",
  //         "The latest technologies",
  //         "Expert assistance in integrating",
  //         "Business analysis to align the validated idea",
  //       ]}
  //       imageUrl={
  //         "https://img.freepik.com/free-photo/results-group-finance-account-success-analyst_1418-34.jpg?w=1060&t=st=1657485056~exp=1657485656~hmac=6aed98d830fa74b84d2db9b39b6b64dbbc2da7424432b0365e75263b29102582"
  //       }
  //     />
  //     <ServicePageItem
  //       serviceTitle={"CLEAR CODE"}
  //       title={"Software development"}
  //       description={`The company develops software for any-sized business and enterprizes. Our offering includes a wide range of software development services to meet the various business needs of our customers and technological goals.`}
  //       features={[
  //         "Building custom solutions from scratch and integration with existing environments.",
  //         "Creating out-of-box solutions with custom features.",
  //         "Code refactoring.",
  //         "Migrating  of the legacy code to new platforms.",
  //         "Redesign of the outdated systems.",
  //       ]}
  //       imageUrl={
  //         "https://img.freepik.com/free-photo/coding-man_1098-18084.jpg?w=1060&t=st=1657485040~exp=1657485640~hmac=34a16f5bd9861b566e6057d70e812a30478ede6cc81a3b48b2ff48c5071f901e"
  //       }
  //     />
  //     <ServicePageItem
  //       serviceTitle={"EVERY STEP ASSISTANCE"}
  //       title={"Product Development"}
  //       description={`We provide a product development service from scratch, building a complete development strategy that looks like plan which lists all the stages involved in the process of software development, along with key stakeholders, goals and objectives, and a project timeline. It includes a plan of action from ideation to product launch.`}
  //       features={[
  //         "Shaping idea into a functional prototype.",
  //         "Proof-of-concept development.",
  //         "Custom product development services.",
  //         "Product upgrade.",
  //         "Design the transformation roadmap.",
  //       ]}
  //       imageUrl={
  //         "https://img.freepik.com/free-photo/creative-agency-business-brain-storm-meeting-presentation-team-discussing-roadmap-to-product-launch-presentation-planning-strategy-new-business-development_609648-2468.jpg?w=1060&t=st=1657485024~exp=1657485624~hmac=024c813e9cc5fe293b4f20257bb3157169b3033eae1d8b1aa048a0a300063e35"
  //       }
  //     />
  //     <ServicePageItem
  //       serviceTitle={" Zero bugs"}
  //       title={"QA and testing"}
  //       description={`We will test the functionality of your software according to the requirements and performance assessments. The functional tests will verify how well the whole system functions, what results it produces and what aspects need to be improved.`}
  //       features={[
  //         "Target software analysis.",
  //         "Mapping out potential deficiencies.",
  //         "Setting goals and establish testing metrics.",
  //         "Suitable testing processes determination.",
  //         "QA roadmap.",
  //       ]}
  //       imageUrl={
  //         "https://images.unsplash.com/photo-1628258334105-2a0b3d6efee1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
  //       }
  //     />
  //     <ServicePageItem
  //       serviceTitle={"QUICK SOFTWARE DELIVERY"}
  //       title={"Devops"}
  //       description={`IT grows establishes from scratch the delivery of software in accordance with DevOps methodologies as well as helps companies improve existing processes, whether it’s development, deployment or QA and testing.`}
  //       features={[
  //         "Simple deploy solutions.",
  //         "Hosting services.",
  //         "Continuous software delivery.",
  //         "Stable environments.",
  //         "Admin-engagement minimization.",
  //       ]}
  //       imageUrl={
  //         "https://img.freepik.com/free-photo/it-engineer-working-with-his-pc_1098-21511.jpg?w=1060&t=st=1657484986~exp=1657485586~hmac=d898ddecc9b24dcaeda6f94b128dd943eedae30026b34fdd078532dfa5ce9591"
  //       }
  //     />
  //     <ServicePageItem
  //       serviceTitle={"FULL SERVICE DEVELOPMENT"}
  //       title={"Smart Teams"}
  //       description={`Our developers have mastered technologies and platforms, which makes them qualified to perform very complex projects in different fields, allowing flexibility to respond to your needs.`}
  //       features={[
  //         "Complementing existing teams with specialists.",
  //         "Building teams with minimum overhead.",
  //         "Highly qualified professionals.",
  //         "Knowledge sharing.",
  //         "Project management tools.",
  //       ]}
  //       imageUrl={
  //         "https://img.freepik.com/free-photo/group-of-young-business-people-working-in-the-office_158595-5210.jpg?w=1060&t=st=1657484957~exp=1657485557~hmac=71294238a594b0960abf0228de21dda83167dc15f571c9015b7f37677ad44a77"
  //       }
  //     />
  //   </ServicesPageSection>
  //   <Footer />
  // </div>
);

export default ServicesPage;
